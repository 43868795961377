import axios from '@axios'
const APP_URL = process.env.VUE_APP_URL

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    //create the actions here
  },
}

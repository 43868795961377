<template>
  <div>
    <b-row>
      <b-col md="9">
        <!-- Table Container Card -->
        <b-card no-body class="mb-0">
          <div class="mt-2 mx-2 ">
            <!-- Table Top -->
            <b-row>
              <b-col
                md="5"
                class="d-flex align-items-center justify-content-start mb-1"
              >
                <label>{{ $t('labels.show') }}</label>

                <v-select
                  v-model="seeMessageOption"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="seeMessageOptions"
                  :clearable="false"
                  @input="filterAnswerMessage"
                  class="per-page-selector d-inline-block mx-50"
                />
              </b-col>

              <b-col
                md="7"
                class="d-flex-column d-sm-flex align-items-center justify-content-center mb-1"
              >
                <b-form-input
                  v-model="searchQuery"
                  @keyup="setUserTyping($event)"
                  class="d-inline-block mr-1"
                  :placeholder="$t('Search')"
                />

                <b-button
                  variant="transparent"
                  @click="reloadReports()"
                  class="d-flex align-items-center justify-content-center"
                >
                  <feather-icon icon="RefreshCwIcon" size="16" class="mr-50" />
                </b-button>

                <div class="d-flex ">
                  <b-dropdown
                    split
                    split-href="#foo/bar"
                    variant="primary"
                    :text="$t(dropDownSelectText)"
                  >
                    <b-dropdown-item
                      @click="selectAll()"
                      >{{ $t('buttons.all') }}</b-dropdown-item
                    >
                    <b-dropdown-item @click="unSelectAll()">
                      {{ $t('buttons.none')}}</b-dropdown-item
                    >
                  </b-dropdown>

                  <b-button
                    variant="danger"
                    v-if="showDeleteIcon()"
                    @click="deleteMessages()"
                    class="d-flex align-items-center justify-content-center ml-1"
                  >
                    <feather-icon icon="Trash2Icon" size="14" class="mr-50" />
                  </b-button>
                </div>
              </b-col>
            </b-row>

            <b-row>
              <b-nav tabs align="center" class="ml-2">
                <b-nav-item
                  :active="currentTab == 'INBOX'"
                  @click="changeTab('INBOX')"
                  >{{$t('received')}}
                </b-nav-item>
                <b-nav-item
                  :active="currentTab == 'OUTBOX'"
                  @click="changeTab('OUTBOX')"
                  >{{$t('sent')}}
                </b-nav-item>
                <b-nav-item
                  v-if="this.$route.params.id"
                  :active="currentTab == 'SEND'"
                  @click="changeTab('SEND')"
                >
                  {{$t('send')}}
                </b-nav-item>
              </b-nav>
            </b-row>
          </div>

          <!--report inbox-->
          <div v-if="currentTab == 'INBOX'">
            <b-overlay
              :show="loadingOverlay"
              :variant="$store.state.appConfig.layout.skin"
              blur="20"
              class="p-50"
              spinner-variant="primary"
              spinner-type="grow"
              spinner-small
              rounded="sm"
              opacity="0.50"
            >
              <b-list-group
                class="mx-1"
                v-if="!isInboxDetailReportView && messageList.length > 0"
              >
                <b-list-group-item
                  v-for="(item,index) in messageList"
                  :key="index"
                  button
                  class="d-flex justify-content-between align-items-center"
                >
                  <div @click="setReportDetail(true,item)">
                    {{ item.subject }}
                    <span
                      v-if="item.createdAt"
                      class="d-block small opacity-50"
                      >{{ formatDate(item.createdAt) }}</span
                    >
                  </div>

                  <div class="user-badge">
                    <b-badge
                      variant="primary"
                      pill
                      >{{item.userId.userName}}</b-badge
                    >

                    <b-form-checkbox
                      v-model="item.selectedMessage"
                      :value="true"
                      v-if="!item.isDelete"
                      :unchecked-value="false"
                      @click="setMessageDelete()"
                    >
                    </b-form-checkbox>

                    <b-badge
                      variant="danger"
                      v-if="item.isDelete"
                      >{{$t('removed')}}</b-badge
                    >
                  </div>
                </b-list-group-item>
              </b-list-group>

              <div
                v-else-if="!isInboxDetailReportView && messageList.length == 0"
              >
                <div class="m-2">{{$t('no_reports_received')}}</div>
              </div>

              <div v-else>
                <b-button
                  variant="primary"
                  style="margin-left: 16px;margin-bottom: 13px"
                  v-if="!isInboxDetailMessageView && reportInboxDetailObject.messages.length > 0"
                  @click="setReportDetail(false,{})"
                >
                  <feather-icon icon="ArrowLeftIcon" size="16" class="mr-50" />
                </b-button>

                <b-list-group
                  class="mx-1"
                  v-if="!isInboxDetailMessageView && reportInboxDetailObject.messages.length > 0"
                >
                  <b-list-group-item
                    v-for="(item,index) in reportInboxDetailObject.messages"
                    :key="index"
                    button
                    class="d-flex justify-content-between align-items-center"
                  >
                    <div @click="setMessageDetail(true,item)">
                      {{ item.subject }}
                      <span
                        v-if="item.createdAt"
                        class="d-block small opacity-50"
                        >{{ formatDate(item.createdAt) }}</span
                      >
                    </div>
                  </b-list-group-item>
                </b-list-group>

                <div v-else>
                  <b-button
                    variant="info"
                    style="margin-left: 24px;"
                    @click="setMessageDetail(false,{})"
                    size="sm"
                  >
                    <feather-icon icon="ArrowLeftIcon" size="16" />
                  </b-button>

                  <b-card :title="messageInboxDetailObject.subject">
                    <b-card-sub-title
                      class="mb-2 d-flex justify-content-between"
                    >
                      <b-badge class="pa-2" variant="info">
                        <span
                          class="font-weight-light"
                          style="margin-right: 6px"
                          >{{$t('from')}}</span
                        >
                        <span
                          >{{messageInboxDetailObject.userId.userName}}</span
                        >
                      </b-badge>
                      <div
                        v-if="messageInboxDetailObject.createdAt"
                        class="font-small-3"
                      >
                        {{messageInboxDetailObject.createdAt | moment}}
                      </div>
                    </b-card-sub-title>

                    <b-card-text
                      v-if="messageInboxDetailObject.message"
                      class="text-capitalize font-italic"
                    >
                      {{messageInboxDetailObject.message}}
                    </b-card-text>

                    <b-card-text v-else>
                      {{$t('no_message_body')}}
                    </b-card-text>

                    <b-card-text>
                      <b-container
                        fluid
                        class="p-2"
                        v-if="messageInboxDetailObject.urlFiles.length > 0"
                      >
                        <b-row>
                          <b-col
                            v-for="(item,index) in messageInboxDetailObject.urlFiles"
                            :key="index"
                          >
                            <b-img
                              @click="openImage(true,item)"
                              thumbnail
                              fluid
                              :src="item"
                              :alt="'Imagen ' + 'index'"
                            ></b-img>
                          </b-col>
                        </b-row>
                      </b-container>
                    </b-card-text>

                    <a
                      class="card-link text-secondary"
                      :aria-expanded="visible ? 'true' : 'false'"
                      aria-controls="collapse-4"
                      v-if="!reportInboxDetailObject.isDelete"
                      @click="replyReport(reportInboxDetailObject)"
                      >{{$t('reply')}}</a
                    >

                    <b-collapse id="collapse-4" v-model="visible" class="mt-2">
                      <validation-observer
                        #default="{ handleSubmit }"
                        ref="refFormObserver"
                      >
                        <b-form
                          @submit.prevent="handleSubmit(onSubmit)"
                          @reset.prevent="resetForm"
                        >
                          <b-card>
                            <b-card-text>
                              <b-input-group
                                :prepend="$t('reply_abv')"
                                class="mb-2 mr-sm-2 mb-sm-0"
                              >
                                <b-form-input
                                  v-model="form.subject"
                                  :placeholder="$t('topic')"
                                  :state="formRuleInput"
                                  aria-describedby="subject-inbox-feedback"
                                  required
                                >
                                </b-form-input>

                                <b-form-invalid-feedback
                                  id="subject-inbox-feedback"
                                >
                                  {{$t('quantity_less_25_characters')}}
                                </b-form-invalid-feedback>
                              </b-input-group>

                              <validation-provider
                                #default="validationContext"
                                name="reason"
                                rules="required"
                              >
                                <b-form-textarea
                                  class="mt-1"
                                  id="textarea-no-resize"
                                  :placeholder="$t('message_text')"
                                  rows="3"
                                  v-model="form.body"
                                  no-resize
                                >
                                </b-form-textarea>

                                <div class="font-small-2 text-danger mt-1">
                                  {{ validationContext.errors[0] }}
                                </div>
                              </validation-provider>

                              <b-form-file
                                multiple
                                class="mt-1"
                                v-model="form.files"
                                @change="showT"
                                :state="formRuleFiles"
                                accept="image/jpeg, image/png,image/jpg"
                              >
                                <template
                                  slot="file-name"
                                  slot-scope="{ names }"
                                >
                                  <b-badge
                                    variant="dark"
                                    >{{ names[0] }}</b-badge
                                  >
                                  <b-badge
                                    v-if="names.length > 1"
                                    variant="dark"
                                    class="ml-1"
                                  >
                                    + {{ names.length - 1 }}
                                    {{$t('more_files')}}
                                  </b-badge>
                                </template>
                              </b-form-file>

                              <b-form-invalid-feedback
                                v-if="!formRuleFiles"
                                id="input-live-feedback"
                              >
                                {{$t('each_file_less_2_mb')}}
                              </b-form-invalid-feedback>
                            </b-card-text>

                            <b-card-text>
                              <b-col>
                                <b-row>
                                  <b-button
                                    variant="success"
                                    :disabled="isSubmitDisable()"
                                    type="submit"
                                    >{{$t('send')}}
                                  </b-button>

                                  <b-button
                                    variant="primary"
                                    class="ml-2"
                                    @click="cancelSendMessageInIOPanel()"
                                  >
                                    {{$t("buttons.cancel")}}
                                  </b-button>
                                </b-row>
                              </b-col>
                            </b-card-text>
                          </b-card>
                        </b-form>
                      </validation-observer>
                    </b-collapse>
                  </b-card>
                </div>
              </div>
            </b-overlay>

            <div class="mx-2 mb-2">
              <!--                        <b-row>-->
              <!--                            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">-->
              <!--                <span class="text-muted">{{ $t('labels.showing') }} {{ dataMeta.from }} {{ $t('labels.to') }} {{ dataMeta.to-->
              <!--                }} {{ $t('labels.of') }} {{ dataMeta.of }} {{ $t('labels.entries') }}</span>-->
              <!--                            </b-col>-->
              <!--                            &lt;!&ndash; Pagination &ndash;&gt;-->
              <!--                            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">-->

              <!--                                <b-pagination v-model="currentPage" :total-rows="totalUsers" :per-page="perPage" first-number-->
              <!--                                              last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">-->
              <!--                                    <template #prev-text>-->
              <!--                                        <feather-icon icon="ChevronLeftIcon" size="18" />-->
              <!--                                    </template>-->
              <!--                                    <template #next-text>-->
              <!--                                        <feather-icon icon="ChevronRightIcon" size="18" />-->
              <!--                                    </template>-->
              <!--                                </b-pagination>-->
              <!--                            </b-col>-->
              <!--                        </b-row>-->
            </div>
          </div>

          <!--report outbox-->
          <div v-else-if="currentTab == 'OUTBOX'">
            <b-overlay
              :show="loadingOverlay"
              :variant="$store.state.appConfig.layout.skin"
              blur="20"
              class="p-50"
              spinner-variant="primary"
              spinner-type="grow"
              spinner-small
              rounded="sm"
              opacity="0.50"
            >
              <b-list-group
                class="mx-1"
                v-if="!isOutboxDetailReportView && messageList.length > 0"
              >
                <b-list-group-item
                  v-for="(item,index) in messageList"
                  :key="index"
                  button
                  class="d-flex justify-content-between align-items-center"
                >
                  <div @click="setReportDetail(true,item)">
                    {{ item.subject }}
                    <span
                      v-if="item.createdAt"
                      class="d-block small opacity-50"
                      >{{ formatDate(item.createdAt) }}</span
                    >
                  </div>

                  <div class="user-badge">
                    <b-badge
                      variant="primary"
                      pill
                      >{{item.userId.userName}}</b-badge
                    >

                    <b-form-checkbox
                      v-model="item.selectedMessage"
                      :value="true"
                      v-if="!item.isDelete"
                      :unchecked-value="false"
                      @click="setMessageDelete()"
                    >
                    </b-form-checkbox>

                    <b-badge
                      variant="danger"
                      v-if="item.isDelete"
                      >{{$t('removed')}}</b-badge
                    >
                  </div>
                </b-list-group-item>
              </b-list-group>

              <div
                v-else-if="!isOutboxDetailReportView && messageList.length == 0"
              >
                <div class="m-2">{{$t('no_reports_sent')}}</div>
              </div>

              <div v-else>
                <b-button
                  variant="primary"
                  style="margin-left: 16px;margin-bottom: 13px"
                  v-if="!isOutboxDetailMessageView && reportOutboxDetailObject.messages.length > 0"
                  @click="setReportDetail(false,{})"
                >
                  <feather-icon icon="ArrowLeftIcon" size="16" class="mr-50" />
                </b-button>

                <b-list-group
                  class="mx-1"
                  v-if="!isOutboxDetailMessageView && reportOutboxDetailObject.messages.length > 0"
                >
                  <b-list-group-item
                    v-for="(item,index) in reportOutboxDetailObject.messages"
                    :key="index"
                    button
                    class="d-flex justify-content-between align-items-center"
                  >
                    <div @click="setMessageDetail(true,item)">
                      {{ item.subject }}
                      <span
                        v-if="item.createdAt"
                        class="d-block small opacity-50"
                        >{{ formatDate(item.createdAt) }}</span
                      >
                    </div>
                  </b-list-group-item>
                </b-list-group>

                <div v-else>
                  <b-button
                    variant="info"
                    style="margin-left: 24px;"
                    @click="setMessageDetail(false,{})"
                    size="sm"
                  >
                    <feather-icon icon="ArrowLeftIcon" size="16" />
                  </b-button>

                  <b-card :title="messageOutboxDetailObject.subject">
                    <b-card-sub-title
                      class="mb-2 d-flex justify-content-between"
                    >
                      <b-badge class="pa-2" variant="info">
                        <span
                          class="font-weight-light"
                          style="margin-right: 6px"
                          >{{$t('from')}}</span
                        >
                        <span
                          >{{messageOutboxDetailObject.userId.userName}}</span
                        >
                      </b-badge>
                      <div
                        v-if="messageOutboxDetailObject.createdAt"
                        class="font-small-3"
                      >
                        {{messageOutboxDetailObject.createdAt | moment}}
                      </div>
                    </b-card-sub-title>

                    <b-card-text
                      v-if="messageOutboxDetailObject.message"
                      class="text-capitalize font-italic"
                    >
                      {{messageOutboxDetailObject.message}}
                    </b-card-text>

                    <b-card-text v-else>
                      {{$t('no_message_body')}}
                    </b-card-text>

                    <b-card-text
                      v-if="messageOutboxDetailObject.urlFiles.length > 0"
                    >
                      <b-container fluid class="p-2">
                        <b-row>
                          <b-col
                            v-for="(item,index) in messageOutboxDetailObject.urlFiles"
                            :key="index"
                          >
                            <b-img
                              @click="openImage(true,item)"
                              thumbnail
                              fluid
                              :src="item"
                              :alt="'Imagen ' + 'index'"
                            ></b-img>
                          </b-col>
                        </b-row>
                      </b-container>
                    </b-card-text>

                    <a
                      class="card-link text-secondary"
                      :aria-expanded="visible ? 'true' : 'false'"
                      aria-controls="collapse-4"
                      v-if="!reportOutboxDetailObject.isDelete"
                      @click="replyReport(reportOutboxDetailObject)"
                      >{{$t('reply')}}</a
                    >

                    <b-collapse id="collapse-4" v-model="visible" class="mt-2">
                      <validation-observer
                        #default="{ handleSubmit }"
                        ref="refFormObserver"
                      >
                        <b-form
                          @submit.prevent="handleSubmit(onSubmit)"
                          @reset.prevent="resetForm"
                        >
                          <b-card>
                            <b-card-text>
                              <b-input-group
                                :prepend="$t('reply_abv')"
                                class="mb-2 mr-sm-2 mb-sm-0"
                              >
                                <b-form-input
                                  v-model="form.subject"
                                  :placeholder="$t('topic')"
                                  :state="formRuleInput"
                                  aria-describedby="subject-outbox-feedback"
                                  required
                                >
                                </b-form-input>

                                <b-form-invalid-feedback
                                  id="subject-outbox-feedback"
                                >
                                  {{$t('quantity_less_25_characters')}}
                                </b-form-invalid-feedback>
                              </b-input-group>

                              <validation-provider
                                #default="validationContext"
                                name="reason"
                                rules="required"
                              >
                                <b-form-textarea
                                  class="mt-1"
                                  id="textarea-no-resize"
                                  :placeholder="$t('message_text')"
                                  rows="3"
                                  v-model="form.body"
                                  no-resize
                                >
                                </b-form-textarea>

                                <div class="font-small-2 text-danger mt-1">
                                  {{ validationContext.errors[0] }}
                                </div>
                              </validation-provider>

                              <b-form-file
                                multiple
                                class="mt-1"
                                v-model="form.files"
                                @change="showT"
                                aria-describedby="files-outbox-feedback"
                                :state="formRuleFiles"
                                accept="image/jpeg, image/png,image/jpg"
                              >
                                <template
                                  slot="file-name"
                                  slot-scope="{ names }"
                                >
                                  <b-badge
                                    variant="dark"
                                    >{{ names[0] }}</b-badge
                                  >
                                  <b-badge
                                    v-if="names.length > 1"
                                    variant="dark"
                                    class="ml-1"
                                  >
                                    + {{ names.length - 1 }}
                                    {{$t('more_files')}}
                                  </b-badge>
                                </template>
                              </b-form-file>

                              <b-form-invalid-feedback
                                id="files-outbox-feedback"
                              >
                                {{$t('each_file_less_2_mb')}}
                              </b-form-invalid-feedback>
                            </b-card-text>

                            <b-card-text>
                              <b-col>
                                <b-row>
                                  <b-button
                                    variant="success"
                                    :disabled="isSubmitDisable()"
                                    type="submit"
                                    >{{$t('send')}}
                                  </b-button>

                                  <b-button
                                    variant="primary"
                                    class="ml-2"
                                    @click="cancelSendMessageInIOPanel()"
                                  >
                                    {{$t("buttons.cancel")}}
                                  </b-button>
                                </b-row>
                              </b-col>
                            </b-card-text>
                          </b-card>
                        </b-form>
                      </validation-observer>
                    </b-collapse>
                  </b-card>
                </div>
              </div>
            </b-overlay>

            <div class="mx-2 mb-2">
              <!--                        <b-row>-->
              <!--                            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">-->
              <!--                <span class="text-muted">{{ $t('labels.showing') }} {{ dataMeta.from }} {{ $t('labels.to') }} {{ dataMeta.to-->
              <!--                }} {{ $t('labels.of') }} {{ dataMeta.of }} {{ $t('labels.entries') }}</span>-->
              <!--                            </b-col>-->
              <!--                            &lt;!&ndash; Pagination &ndash;&gt;-->
              <!--                            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">-->

              <!--                                <b-pagination v-model="currentPage" :total-rows="totalUsers" :per-page="perPage" first-number-->
              <!--                                              last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">-->
              <!--                                    <template #prev-text>-->
              <!--                                        <feather-icon icon="ChevronLeftIcon" size="18" />-->
              <!--                                    </template>-->
              <!--                                    <template #next-text>-->
              <!--                                        <feather-icon icon="ChevronRightIcon" size="18" />-->
              <!--                                    </template>-->
              <!--                                </b-pagination>-->
              <!--                            </b-col>-->
              <!--                        </b-row>-->
            </div>
          </div>

          <!--report send-->
          <div v-else-if="currentTab == 'SEND'">
            <b-card class="ma-2">
              <b-card-text>
                <div v-if="!sendMessageObject.error" style="margin-left: 7px">
                  <b-form-text
                    id="input-live-help"
                    >{{$t('answering_to')}}</b-form-text
                  >

                  <div v-if="sendMessageObject.user.userName">
                    <b-input-group
                      prepend="@"
                      class="mb-2 mr-sm-2 mb-sm-0"
                      style="max-width: 300px"
                    >
                      <b-form-input
                        readonly
                        id="input-live"
                        aria-describedby="input-live-help"
                        class="font-weight-bold"
                        :placeholder="sendMessageObject.user.userName"
                      >
                      </b-form-input>
                    </b-input-group>
                  </div>

                  <strong v-else>...</strong>
                </div>

                <div v-else>{{$t('error_loading_user_ information')}}</div>
              </b-card-text>

              <validation-observer
                #default="{ handleSubmit }"
                ref="refFormObserver"
              >
                <b-form
                  @submit.prevent="handleSubmit(onSubmit)"
                  @reset.prevent="resetForm"
                  disabled
                >
                  <b-overlay
                    :show="loadingOverlay"
                    :variant="$store.state.appConfig.layout.skin"
                    blur="2"
                    class="p-50"
                    spinner-variant="primary"
                    spinner-type="grow"
                    spinner-small
                    rounded="sm"
                    opacity="0.50"
                  >
                    <b-card-text>
                      <b-input-group class="">
                        <b-form-input
                          v-model="form.subject"
                          :placeholder="$t('topic')"
                          :state="formRuleInput"
                          aria-describedby="subject-send-feedback"
                          required
                        ></b-form-input>

                        <b-form-invalid-feedback id="subject-send-feedback">
                          {{$t('quantity_less_25_characters')}}
                        </b-form-invalid-feedback>
                      </b-input-group>

                      <validation-provider
                        #default="validationContext"
                        name="reason"
                        rules="required"
                      >
                        <b-form-textarea
                          class="mt-1"
                          id="textarea-no-resize"
                          :placeholder="$t('message_text')"
                          rows="3"
                          v-model="form.body"
                          no-resize
                        >
                        </b-form-textarea>

                        <div class="font-small-2 text-danger mt-1">
                          {{ validationContext.errors[0] }}
                        </div>
                      </validation-provider>

                      <b-form-file
                        multiple
                        class="mt-1"
                        v-model="form.files"
                        @change="showT"
                        aria-describedby="files-send-feedback"
                        :state="formRuleFiles"
                        accept="image/jpeg, image/png,image/jpg"
                      >
                        <template slot="file-name" slot-scope="{ names }">
                          <b-badge variant="dark">{{ names[0] }}</b-badge>
                          <b-badge
                            v-if="names.length > 1"
                            variant="dark"
                            class="ml-1"
                          >
                            + {{ names.length - 1 }} {{$t('more_files')}}
                          </b-badge>
                        </template>
                      </b-form-file>

                      <b-form-invalid-feedback id="files-send-feedback">
                        {{$t('each_file_less_2_mb')}}
                      </b-form-invalid-feedback>
                    </b-card-text>

                    <b-card-text>
                      <b-col>
                        <b-row>
                          <b-button
                            :disabled="isSubmitDisable()"
                            variant="success"
                            type="submit"
                            >{{$t('send')}}</b-button
                          >

                          <b-button
                            variant="primary"
                            class="ml-2"
                            @click="cancelSendMessage()"
                          >
                            {{$t("buttons.cancel")}}
                          </b-button>
                        </b-row>
                      </b-col>
                    </b-card-text>
                  </b-overlay>
                </b-form>
              </validation-observer>
            </b-card>
          </div>
        </b-card>
      </b-col>

      <!--quick options-->
      <b-col class="pt-1 pt-md-0" md="3">
        <b-card no-body class="p-0 py-1">
          <b-card-body>
            <b-card-text>
              <b-row class="d-flex align-items-center justify-content-center">
                <b-col class="round p-2">
                  <div
                    class="d-flex align-items-center justify-content-center"
                    id="answered-1"
                  >
                    <feather-icon icon="UserCheckIcon" size="30" class="" />
                    <div
                      v-b-tooltip.hover
                      title="Tooltip directive content"
                      style="margin-left: 10px"
                    >
                      {{answered}}
                    </div>
                    <b-tooltip
                      target="answered-1"
                      :title="$t('answered_messages')"
                    ></b-tooltip>
                  </div>
                </b-col>

                <b-col class="round p-2">
                  <div
                    class="d-flex align-items-center justify-content-center"
                    id="notAnswered-1"
                  >
                    <feather-icon icon="UserXIcon" size="30" class="" />
                    <div style="margin-left: 10px">{{notAnswered}}</div>
                    <b-tooltip
                      target="notAnswered-1"
                      :title="$t('unanswered_messages')"
                    ></b-tooltip>
                  </div>
                </b-col>

                <b-col class="round p-2">
                  <div
                    class="d-flex align-items-center justify-content-center"
                    id="created-1"
                  >
                    <feather-icon icon="UserPlusIcon" size="30" class="" />
                    <div style="margin-left: 10px">{{allReports}}</div>
                    <b-tooltip
                      target="created-1"
                      :title="$t('posts_created')"
                    ></b-tooltip>
                  </div>
                </b-col>

                <b-col class="round p-2">
                  <div
                    class="d-flex align-items-center justify-content-center"
                    id="deleted-1"
                  >
                    <feather-icon icon="UserMinusIcon" size="30" class="" />
                    <div style="margin-left: 10px">{{deletedReport}}</div>
                    <b-tooltip
                      target="deleted-1"
                      :title="$t('deleted_messages')"
                    ></b-tooltip>
                  </div>
                </b-col>
              </b-row>
            </b-card-text>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <div>
      <b-modal
        size="xl"
        hide-footer
        v-model="modalImageShow.show"
        centered
        :title="$t('Image')"
      >
        <b-img :src="modalImageShow.img" fluid-grow alt="imagen"></b-img>
      </b-modal>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import axios from '@axios'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import userStoreModule from '../../apps/user/userStoreModule'
import { onUnmounted } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import msgReportStoreModule from './msgReportStoreModule'
import moment from "moment";
import { BForm, BFormFile, BFormInvalidFeedback } from 'bootstrap-vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, integer } from '@validations'
import { translatableText } from '@core/utils/utils'
import { axiosErrorHandle } from '@core/utils/errorHandler'

export default {
  name: 'messageModulePage',

  components: {
      BForm,
      BFormInvalidFeedback,
    BFormFile,
    vSelect,
    ValidationProvider,
    ValidationObserver
  },

  setup() {
    const toast = useToast()
    const USER_APP_STORE_MODULE_NAME = 'app-user'
    const USER_APP_STORE_MODULE_NAME1 = 'app-msgReport'
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, msgReportStoreModule)
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
      if (store.hasModule(USER_APP_STORE_MODULE_NAME1)) store.unregisterModule(USER_APP_STORE_MODULE_NAME1)
    })

      const {
          refFormObserver,
          getValidationState,
          resetForm,
          clearForm,
      } = formValidation(() => {})

      return {
          // Form Validation
          resetForm,
          refFormObserver,
          getValidationState,
      }
  },

  data() {
    return {
      loadingListUsers: false,
      loadingOverlay: false,
      sendMessageObject: { user: '', error: '' },
      isInboxDetailReportView: '',
      isOutboxDetailReportView : '',
      reportInboxDetailObject: '',
      reportOutboxDetailObject: '',
      messageOutboxReportObject: '',
      currentTab: 'INBOX',
      file: [],
      seeMessage: 'Todos',
      selectMessages: 'Niguno',
      searchQuery: '',
      isInboxDetailMessageView: false,
      isOutboxDetailMessageView: false,
      messageInboxDetailObject: '',
      messageOutboxDetailObject: '',
      messageList: [],
      inboxOriginalMessageList: [],
      outboxOriginalMessageList: [],
      dropDownSelectText: 'buttons.none',
      reportsDeleted : '',
      visible: false,
      modalImageShow: {
        show : false,
        img  : ''
      },
      form: {
        subject: '',
        body: '',
        files: [],
      },
    }
  },

    filters: {
        moment: function (date) {
            return moment(date).format("DD/MM/YYYY hh:mm:ss a");
        },
    },

  computed: {

      seeMessageOptions() {
          return [
              this.$t('deleted_messages'), this.$t('buttons.all'),
          ]
      },

    /**
     * input field form rules
     */
    formRuleInput() {
      if (this.form.subject)
        return this.form.subject.length > 25 ? false : true
      else
        return  null
    },

    /**
     * file field form rules
     */
    formRuleFiles(){
      if (this.form.files.length > 0){
        let found = this.form.files.find(file => file.size > 2000000)
        if (found)
          return false
        else
          return true
      }
      return null
    },

    /**
     * updates the value of the select component depending on what is in the generic list
     */
    seeMessageOption:{
      set : function(value) {
        this.seeMessage = value
      },
      get : function() {
        if (this.messageList.find((item) => item.isDelete === true)){
          return this.$t("deleted_messages")
        }
        return  this.$t('buttons.all')
      }
    },

    /**
     * the selected currency
     */
    currencySelect() {
      return this.$store.state.whitelabelCurrencyNabvar.currency
    },

    /**
     * filter the number of reports attended
     * */
    answered() {
      return this.outboxOriginalMessageList.filter(messages => messages.isReadReport).length
    },

    /**
     * filter the number of unattended reports
     */
    notAnswered() {
      return this.inboxOriginalMessageList.filter(messages => !messages.isReadReport).length
    },

    /**
     * filter the number of deleted reports
     */
    deletedReport() {
      return this.reportsDeleted.length
    },

    /**
     * filter the number of created reports
     */
    allReports() {
      return this.reportsDeleted.length + this.inboxOriginalMessageList.length + this.outboxOriginalMessageList.length
    },

    /**
     * the selected whitelabel
     */
    whitelabelSelectNavbar() {
      return this.$store.state.whitelabelCurrencyNabvar.whitelabel
    },

  },

  methods: {

    formatDate(value) {
      return moment(value).format('DD/MM/YYYY hh:mm a')
    },

    /**
     * determines when to disable the submit button
     */
    isSubmitDisable(){
      if (this.formRuleInput ==false || this.formRuleFiles == false){
        return true
      }
      return false
    },

    showT() {
      console.info(this.form.files)
    },

    /**
     * create or respond to a created report, depending on where the request is made
     * @event event
     */
    async onSubmit(event) {
      // event.preventDefault()

      //if the form has files to submit
      if (this.form.files.length > 0 ) {

        // upload file
        const formData = new FormData()
        let validate = this.form.files.map(fileUpload => {
          if (this.validateFileExtensions(fileUpload)) {
            formData.append('images', fileUpload) //images[]
            return true
          } else {
            return false
          }
        })

        if (validate.includes(false)) {

          this.form.files = []

        } else {

          if (this.currentTab == 'INBOX') {
            //create a message with images
            await this.uploadImageAndCreateMessage({
              topic: 'Re:'+ ' ' + this.form.subject,
              body: this.form.body,
              formData: formData,
              reportId: this.reportInboxDetailObject._id,
              clientId: this.reportInboxDetailObject.userId.client,
              //toUserId: this.reportInboxDetailObject.userId._id,
            })

          } else if (this.currentTab == 'OUTBOX') {
            //create a message with images
            await this.uploadImageAndCreateMessage({
              topic: 'Re:'+ ' ' + this.form.subject,
              body: this.form.body,
              formData: formData,
              reportId: this.reportOutboxDetailObject._id,
              clientId: this.reportOutboxDetailObject.userId.client,
              //toUserId: this.reportOutboxDetailObject.userId._id,
            })

          } else if (this.currentTab == 'SEND') {
            //create a message with images
            await this.uploadImageAndCreateMessage({
              topic: this.form.subject,
              body: this.form.body,
              formData: formData,
              toUserId: this.sendMessageObject.user._id,
              createByAdmin : true,
              clientId : this.sendMessageObject.user.client[0]._id, //hotfix
            })
          }

          this.onReset()
        }

      } else {

        if (this.currentTab == 'INBOX') {
          //create a message without images
          this.createMessage({
            topic: 'Re:'+ ' ' + this.form.subject,
            body: this.form.body,
            reportId: this.reportInboxDetailObject._id,
          })

        } else if (this.currentTab == 'OUTBOX') {
          //create a message without images
          this.createMessage({
            topic: 'Re:'+ ' ' + this.form.subject,
            body: this.form.body,
            reportId: this.reportOutboxDetailObject._id,
          })

        } else if (this.currentTab == 'SEND') {
          //create a message without images
          await this.createMessage({
            topic: this.form.subject,
            body: this.form.body,
            toUserId: this.sendMessageObject.user._id,
            createByAdmin : true
          })
        }
        this.onReset()
      }

    },

    /**
     * Validate .extension
     * */
    validateFileExtensions(file) {
      const extensions = ['jpg', 'jpeg', 'png']
      const extension = file.name.replace(/.*\./, '').toLowerCase()
      if (extensions.indexOf(extension) < 0) {  // Wasn't found
        this.showNotification(this.$t('file_type_allowed') + ' ' + extension, 'FileIcon', 'primary')
        return false
      } else {
        return true
      }
    },

    /**
     * Create a request to build a report with the form data
     * @param {topic, body, imagesUrl = [],reportId,imagesUrl = [], toUserId = '', createByAdmin = false }
     * the topic, the subject, the body , the images urls of the message, the id of the user to send the message,
     * if the message is created, it says that it was created by an administrator
     */
    async createMessage({ topic, body, reportId = '', imagesUrl = [], toUserId = '', createByAdmin = false }) {

      this.loadingOverlay = true

      const userData = JSON.parse(localStorage.getItem('userData'))

      //map the object to send
      let objectSend = {
        reportId: reportId ? reportId : undefined,
        subject: topic,
        message: body,
        userId: toUserId ? toUserId : userData._id,
        urlFiles: imagesUrl,
        createByAdmin : createByAdmin,
        adminId : createByAdmin ? userData._id : ''
      }

      await axios.post(`${process.env.VUE_APP_URL}/reportusermsg/create`, objectSend)
        // eslint-disable-next-line no-unused-vars
        .then(async (response) => {

          this.showNotification(this.$t('message_sent'), 'SendIcon', 'primary')

          this.loadingOverlay = false

          //if a message is answered
          if(reportId)
            await this.sendReportRead(reportId)
          else
          //refresh all reports
          await this.reloadReports()

          //exit detail views
          this.setMessageDetail(false, {})
          this.setReportDetail(false, {})

        })
        .catch(async (error) => {
          this.showNotification(await translatableText({ text: axiosErrorHandle(error)}), 'SendIcon', 'danger')

          this.loadingOverlay = false

        })
        .finally(() => {
          this.loadingOverlay = false
        })

    },

    /**
     * Request to load all user reports
     */
    async reloadReports() {

      this.loadingOverlay = true

      const userData = JSON.parse(localStorage.getItem('userData'))

      await axios.get(`${process.env.VUE_APP_URL}/reportusermsg/show/branch/${userData._id}`)
        .then((response) => {

          this.showNotification(this.$t('updated_reports'), 'FileIcon', 'primary')

          //reset the main list of reports
          this.outboxOriginalMessageList = []
          this.inboxOriginalMessageList = []

          if (Array.isArray(response.data)) {

            //filter and save deleted messages
            this.reportsDeleted = response.data.filter((item) => item.isDelete)

            //filter undeleted messages
            let filterNotDeleteMessage = response.data.filter((item) => !item.isDelete)

            //filters messages that are not answered
            let filterInboxArray = filterNotDeleteMessage.filter((item) => !item.isReadReport)
            //We put the reports in the outbox
            this.inboxOriginalMessageList = filterInboxArray.sort(function(a,b){
              return new Date(b.createdAt) - new Date(a.createdAt);
            });

            //filter the messages that were answered
            let filterOutboxArray = filterNotDeleteMessage.filter((item) => item.isReadReport)
            //We put the reports in the outbox
            this.outboxOriginalMessageList = filterOutboxArray.sort(function(a,b){
              return new Date(b.createdAt) - new Date(a.createdAt);
            });

            this.loadingOverlay = false

          }

        })
        .catch(async (error) => {
          this.showNotification(await translatableText({ text: axiosErrorHandle(error)}), 'FileIcon', 'danger')
        })
        .finally(() => {
          this.updateState()
          this.loadingOverlay = false
        })

    },

    /**
     * When the message has images it sends the images to the server and with the response url it sends the message
     * @param {topic, body ,formData} the subject, the body and images of the message
     */
    async uploadImageAndCreateMessage({ topic, body, formData, reportId = '', toUserId = '' ,clientId, createByAdmin = false}) {

      this.loadingOverlay = true
      const userData = JSON.parse(localStorage.getItem('userData'))

      if (!clientId){
        this.showNotification(this.$t('verify_clientId_user'),'ImageIcon','danger')
        this.loadingOverlay = false
        return
      }

      formData.append('path', '/')
      await axios.post(`${process.env.VUE_APP_URL}/setImages/${clientId}`, formData)
        .then(async (response) => {
          console.info(response)
          if (response.data.files && response.data.files.length > 0) {
            await this.createMessage({
              topic: topic,
              body: body,
              reportId: reportId,
              imagesUrl: response.data.files,
              toUserId: toUserId ? toUserId : userData._id,
              createByAdmin : createByAdmin
            })
          } else {
            this.showNotification(this.$t('Error'))
          }
        })
        .catch(async (error) => {
           this.showNotification(await translatableText({ text: axiosErrorHandle(error)}), 'ImageIcon', 'danger')
        })
        .finally(() => {
          this.loadingOverlay = false
        })

    },

    /**
     * Request to delete all reports that have the property selectedMessage in true
     */
    async deleteMessages() {

      this.loadingOverlay = true

      //filter reports
      let deleteMessages = this.messageList.filter((item) => item.selectedMessage)

      //get the report ids to send to the backend
      let messageDeleteIds = deleteMessages.map(message => {
        return message._id
      })

      await axios.delete(`${process.env.VUE_APP_URL}/reportusermsg/destroy`, { data: { ids: messageDeleteIds } })
        // eslint-disable-next-line no-unused-vars
        .then(async (response) => {

          this.loadingOverlay = false

          this.showNotification(this.$t('deleted_reports'), 'TrashIcon', 'primary')

          await this.reloadReports()

        })
        .catch(async (error) => {
           this.showNotification(await translatableText({ text: axiosErrorHandle(error)}), 'TrashIcon', 'danger')
        })
        .finally(() => {
          this.loadingOverlay = false
        })

    },

    /**
     * send a request to mark a report as attended
     * @param reportId report identifier
     */
    async sendReportRead(reportId){
      await axios.post(`${process.env.VUE_APP_URL}/reportusermsg/update/${reportId}`, {isReadReport:true})
        .then( async () => {
          await this.reloadReports()
        })
        .catch( () => {
          //error setting a read message
        })
        .finally(()=>{
        });

    },

    /**
     * Displays a toast component with a message
     * @param message (message,icon,variant) message to display in the component , iconName and toast style
     */
    showNotification(message, icon, variant) {
      // alert message
      this.$toast({
        component: ToastificationContent,
        props: {
          title: message,
          icon: icon,
          variant: variant,
        },
      })
    },

    /**
     * go to detail view
     */
    setMessageDetail(flag, message) {
      this.visible = false
      if (this.currentTab == 'INBOX') {
        this.isInboxDetailMessageView = flag
        this.messageInboxDetailObject = message
      } else if (this.currentTab == 'OUTBOX') {
        this.isOutboxDetailMessageView = flag
        this.messageOutboxDetailObject = message
      }
    },

    /**
     * filter the selected messages
     * @param selected option
     */
    filterAnswerMessage(option) {

      let message = []
      if (this.currentTab == 'INBOX') {
        message = this.inboxOriginalMessageList
      } else if (this.currentTab == 'OUTBOX') {
        message = this.outboxOriginalMessageList
      }

      switch (option) {
        // case 'Sin responder':
        //   this.messageList = message.filter(message => !message.isAnwered)
        //   break
        // case 'Atendidos':
        //   this.messageList = message.filter(message => message.isAnwered)
        //   break
        case this.$t('buttons.all'):
          this.messageList = message
          break
        case this.$t('deleted_messages'):
          this.messageList = this.reportsDeleted
          break
        default:
          this.messageList = message
      }

    },

    /**
     * Change the value "selectedMessage" of all reports to "false" used to control the selected messages
     */
    unSelectAll() {
      for (let i in this.messageList) {
        this.messageList[i].selectedMessage = false
      }
      this.dropDownSelectText = 'buttons.none'
      this.showDeleteIcon()
    },

    /**
     * Change the value "selectedMessage" of all reports to "true" used to control the selected messages
     */
    selectAll() {
      for (let i in this.messageList) {
        this.messageList[i].selectedMessage = true
      }
      this.dropDownSelectText = 'buttons.all'
      this.showDeleteIcon()
    },

    /**
     * make the delete button visible
     */
    setMessageDelete() {
      this.showDeleteIcon()
    },

    /**
     * change the current tab
     * @param tabName
     */
    changeTab(tabName) {
      this.visible = false
      this.onReset()
      this.currentTab = tabName
      this.updateState()
    },

    /**
     * updates the generic list, this list is the one that the component works with
     */
    updateState() {
      if (this.currentTab == 'INBOX') {
        this.messageList = this.inboxOriginalMessageList
      } else if (this.currentTab == 'OUTBOX') {
        this.messageList = this.outboxOriginalMessageList
      }
    },

    /**
     * determines when to show the delete button
     * This happens if there is an element marked as delete
     * @returns {boolean}
     */
    showDeleteIcon() {
      let found
      if (this.messageList.length > 0) {
        found = this.messageList.find(message => message.selectedMessage == true)
        return found ? true : false
      }
    },

    /**
     * checks the parameters in the route to determine if a new report is going to be sent to the user
     */
    checkRouterParams() {
      const id = this.$route.params.id
      if (id) {
        this.currentTab = 'SEND'
        this.updateState()
        this.fetchUser(id)
      }
    },

    /**
     * get the user info
     * @param id
     */
    fetchUser(id) {
      this.loadingOverlay = true
      store.dispatch('app-user/fetchUser', { id: id })
        .then(response => {
          this.sendMessageObject.user = response.data
          this.sendMessageObject.error = ''
          this.loadingOverlay = false
        })
        .catch(error => {
          this.sendMessageObject.error = error
          this.loadingOverlay = false
        })
    },

    /**
     * cancel sending a report
     */
    cancelSendMessage() {
      this.$router.push({ name: 'apps-messages-list' })
      this.currentTab = 'INBOX'
      this.onReset()
      this.updateState()
    },

    /**
     * cancel sending a report
     */
    cancelSendMessageInIOPanel(){
      this.visible = false
      this.onReset()
    },

    /**
     * restart the form
     * @param event event
     */
    onReset(event) {

        this.resetForm()

      if (event)
        event.preventDefault()
      // Reset our form values
      this.form.subject = ''
      this.form.body = ''
      this.form.files = []
      this.$nextTick(() => {
        this.show = true
      })
    },

    /**
     * is executed when the user is typing in the search form
     */
    setUserTyping() {
      if (this.currentTab == 'INBOX') {
        if (this.searchQuery)
          this.messageList = this.inboxOriginalMessageList.filter(item => item.subject.toLowerCase().includes(this.searchQuery.toLowerCase()))
        else
          this.messageList = this.inboxOriginalMessageList
      } else if (this.currentTab == 'OUTBOX') {
        if (this.searchQuery)
          this.messageList = this.outboxOriginalMessageList.filter(item => item.subject.toLowerCase().includes(this.searchQuery.toLowerCase()))
        else
          this.messageList = this.outboxOriginalMessageList
      }
    },

    /**
     * saves the object to display in the detail view and goes to that view
     * @param flag
     * @param report
     */
    setReportDetail(flag, report) {
      if (this.currentTab == 'INBOX') {
        this.isInboxDetailReportView = flag
        this.reportInboxDetailObject = report
      } else if (this.currentTab == 'OUTBOX') {
        this.isOutboxDetailReportView = flag
        this.reportOutboxDetailObject = report
      }
    },

    /**
     * open a selected image in a new modal
     * @param flag
     * @param imgUrl
     */
    openImage(flag,imgUrl){
      this.modalImageShow.img = imgUrl
      this.modalImageShow.show = flag
    },

    replyReport(report){
      this.visible = true
      this.form.subject = report.subject
    }

  },

  async created() {
    this.updateState()
    this.reloadReports()
    this.checkRouterParams()
  },

}
</script>

<style scoped>

.select {
    flex-wrap: nowrap !important;
}
.opacity-50 {
  opacity: .5;
}

.user-badge{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap:0.5rem;
}
</style>
